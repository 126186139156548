"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = require("draft-js");
var _default = text => {
  const contentState = _draftJs.ContentState.createFromText(text);
  const rawContentState = (0, _draftJs.convertToRaw)(contentState);
  return JSON.stringify(rawContentState);
};
exports.default = _default;