"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  meurio: ['#039046', '#2BBAE2', '#FFCF01', '#EF7F3A', '#DF499A', '#8C78B7', '#353A3D'],
  meurecife: ['#0FA7AC', '#F0593E', '#F4ED2F'],
  minhablumenau: ['#05A9CC', '#D6DF21', '#4B4C4D'],
  minhacampinas: ['#12868B', '#FCB814', '#D01F53'],
  minhacuritiba: ['#F9A51B', '#1EABB4', '#3C4F56'],
  minhagaropaba: ['#00ABCD', '#BBD76A', '#353B40'],
  minhaouropreto: ['#FDC93B', '#47928D', '#45494D'],
  minhaportoalegre: ['#F68B1F', '#F15622', '#1A9CAC'],
  minhasampa: ['#D51067', '#FA9ABA', '#646569', '#CAC8C8', '#00C4B3', '#00A7CE', '#8C84D6', '#FFC627'],
  nossascidades: ['#D51067', '#FA9ABA', '#646569', '#CAC8C8', '#00C4B3', '#00A7CE', '#8C84D6', '#FFC627', '#039046', '#2bbae2', '#ffcf01', '#ef7f3a', '#ed1c24', '#df499a', '#8c78b7', '#353a3d', '#0fa7ac', '#f0593e', '#f4ed2f', '#05a9cc', '#d6df21', '#4b4c4d', '#12868b', '#fcb814', '#d01f53', '#f9a51b', '#1eabb4', '#3c4f56', '#00abcd', '#bbd76a', '#353b40', '#fdc93b', '#47928d', '#fdc93b', '#47928d', '#45494d', '#f68b1f', '#f15622', '#1a9cac', '#ffffff'],
  nuestrasciudades: ['#D51067', '#FA9ABA', '#646569', '#CAC8C8', '#00C4B3', '#00A7CE', '#8C84D6', '#FFC627', '#039046', '#2bbae2', '#ffcf01', '#ef7f3a', '#ed1c24', '#df499a', '#8c78b7', '#353a3d', '#0fa7ac', '#f0593e', '#f4ed2f', '#05a9cc', '#d6df21', '#4b4c4d', '#12868b', '#fcb814', '#d01f53', '#f9a51b', '#1eabb4', '#3c4f56', '#00abcd', '#bbd76a', '#353b40', '#fdc93b', '#47928d', '#fdc93b', '#47928d', '#45494d', '#f68b1f', '#f15622', '#1a9cac', '#ffffff'],
  ourcities: ['#D51067', '#FA9ABA', '#646569', '#CAC8C8', '#00C4B3', '#00A7CE', '#8C84D6', '#FFC627', '#039046', '#2bbae2', '#ffcf01', '#ef7f3a', '#ed1c24', '#df499a', '#8c78b7', '#353a3d', '#0fa7ac', '#f0593e', '#f4ed2f', '#05a9cc', '#d6df21', '#4b4c4d', '#12868b', '#fcb814', '#d01f53', '#f9a51b', '#1eabb4', '#3c4f56', '#00abcd', '#bbd76a', '#353b40', '#fdc93b', '#47928d', '#fdc93b', '#47928d', '#45494d', '#f68b1f', '#f15622', '#1a9cac', '#ffffff']
};