"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = require("draft-js");
//
// USAGE
// style = alignment you want (e.g. "left")
// removeStyles = alignments to remove (["center", "right"])
//
var _default = exports.default = function _default(editorState, style) {
  let removeStyles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  let currentContent = editorState.getCurrentContent();
  let selection = editorState.getSelection();
  let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
  let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
  let selectionIsBackward = selection.getIsBackward();
  let changes = {
    anchorOffset: 0,
    focusOffset: focusBlock.getLength()
  };
  if (selectionIsBackward) {
    changes = {
      focusOffset: 0,
      anchorOffset: anchorBlock.getLength()
    };
  }
  let selectWholeBlocks = selection.merge(changes);
  let modifiedContent = _draftJs.Modifier.applyInlineStyle(currentContent, selectWholeBlocks, style);
  let finalContent = removeStyles.reduce(function (content, style) {
    return _draftJs.Modifier.removeInlineStyle(content, selectWholeBlocks, style);
  }, modifiedContent);
  return _draftJs.EditorState.push(editorState, finalContent, 'change-inline-style');
};