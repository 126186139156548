export default {
  'page--account-login.label.email': 'Email',
  'page--account-login.label.password': 'password',
  'page--account-login.placeholder.email': 'example@email.com',
  'page--account-login.loading': 'Loading...',
  'page--account-login.signin': 'Get in',
  'page--account-login.ask-register': 'Not yet registered?',
  'page--account-login.cta-signup': 'Click to create an account.',
  'page--account-login.auth.error-message': 'Incorrect password.',
  'page--account-login.auth.error-message.retrieve-password.link': 'Forgot your password?',

  'p--account-login.form.email.validation.required': 'Enter your email',
  'p--account-login.form.email.validation.invalid': 'Invalid email',
  'p--account-login.form.password.validation.required': 'Inform your password',

  'page--account-register.title': 'Create your Bonde account.',
  'page--account-register.form.name.label': 'Name',
  'page--account-register.form.name.placeholder': 'Your name',
  'page--account-register.form.name.validation.required': 'Enter your name',
  'page--account-register.form.lastname.label': 'Last name',
  'page--account-register.form.lastname.placeholder': 'Last name',
  'page--account-register.form.email.label': 'Email',
  'page--account-register.form.email.placeholder': 'example@email.com.br',
  'page--account-register.form.email.validation.required': 'Enter your email address',
  'page--account-register.form.email.validation.invalid-email-format': 'Invalid email',
  'page--account-register.form.password.label': 'password',
  'page--account-register.form.password.label.validation.required': 'Enter a password',
  'page--account-register.form.password.label.validation.min-length': 'Your password must be at least 8 characters long.',
  'page--account-register.form.password-confirm.label': 'Confirm your password',
  'page--account-register.form.password-confirm.label.validation.match': 'Password does not match',
  'page--account-register.form.submit-button.default': 'Create an account',
  'page--account-register.form.submit-button.saving': 'Saving ...',

  'p--account-retrieve.title.first-line': 'What is your email address?',
  'p--account-retrieve.title.second-line': 'We will send a new password to you.',
  'p--account-retrieve.form.email.validation.required': 'Enter your email address',
  'p--account-retrieve.form.email.validation.invalid-email-format': 'Invalid email',
  'p--account-retrieve.form.email.label': 'Email',
  'p--account-retrieve.form.email.placeholder': 'example@email.com.br',
  'p--account-retrieve.form.submit-button.sending': 'Sending ...',
  'p--account-retrieve.form.submit-button.default': 'To send',

  'page--account-edit.header.title': 'My account',
  'page--account-edit.header.tabs.user': 'User',
  'page--account-edit.form.name.label': 'Name',
  'page--account-edit.form.lastname.label': 'Last name',
  'page--account-edit.form.email.label': 'Email',

  'page--subscription-edit.title': 'Donation Data',
  'page--subscription-edit.helper-text': 'Select below which donation information you want to change:',
  'page--subscription-edit.button.creditcard': 'Credit card',
  'page--subscription-edit.button.recurring': 'Date of donation',
  'page--subscription-edit.cancel-subscription.confirm': 'You are about to cancel your subscription. Are you sure you want to continue?',
  'page--subscription-edit.link.cancel-subscription': 'I want to cancel my subscription.',

  'form--subscription-creditcard.helper-text': 'Change your credit card details by filling in the fields below. Your donation will continue the same but, from the moment you save the data below, the amount will be charged on this new card; )',

  'form--subscription-creditcard.previous-data.title': 'Data from the last card',
  'form--subscription-creditcard.previous-data.name': 'Name',
  'form--subscription-creditcard.previous-data.expiration-date': 'Shelf life',

  'form--subscription-creditcard.form.number.label': 'Number',
  'form--subscription-creditcard.form.number.placeholder': 'Ex: 0000 0000 0000 0000',
  'form--subscription-creditcard.form.name.label': 'Name',
  'form--subscription-creditcard.form.name.placeholder': '(same as on the card)',
  'form--subscription-creditcard.form.expiration-date.label': 'Shelf life',
  'form--subscription-creditcard.form.expiration-date.placeholder': '00/00',
  'form--subscription-creditcard.form.cvv.label': 'CVV',
  'form--subscription-creditcard.form.cvv.placeholder': 'Ex: 000',
  'form--subscription-creditcard.form.submit-button.text': 'To save',
  'form--subscription-creditcard.form.validation.required': 'Required',

  'form--subscription-recurring.helper-text': 'Fill in the fields below to change the date your donation is charged. Your donation will continue the same but, from the moment you save the data below, the amount will be charged on this new card; )',
  'form--subscription-recurring.form.process-at.label': 'New billing date',
  'form--subscription-recurring.form.process-at.placeholder': 'Ex: DD / MM / YYYY',
  'form--subscription-recurring.form.submit-button.text': 'To save',
  'form--subscription-recurring.form.validation.required': 'Required',
  'form--subscription-recurring.form.validation.invalid-date-format': 'Invalid date format',

  'notification--generic-request-error.title': 'Oops!',
  'notification--generic-request-error.message': 'It seems that he had some technical problem with this last request. Please try again later.',

  'notification--generic-save-success.title': 'Yay!',
  'notification--generic-save-success.message': 'The request was successfully made and your data is safely saved.',

  'notification--slug-updated-message.title': 'Important',
  'notification--slug-updated-message.message': 'The slug of your mobilization was changed. If you do some DNS redirection via CNAME, be sure to update it.',

  'notification--message-pressure-targets-remove-all.title': 'Remember if...',
  'notification--message-pressure-targets-remove-all.message': 'Do not forget that you need to click the save button in the upper right corner of the screen for the action to be permanent. If you want to revert the removed targets, simply refresh the page. (:',

  'notification--account-password-retrieve-success.title': 'Password recovery',
  'notification--account-password-retrieve-success.message': 'We just sent you an email with your new password. Check your email inbox to access BONDE again. So relax, you can change this password whenever you want in your account settings.',

  'notification--community-invite-success.title': 'Yay!',
  'notification--community-invite-success.message': 'Invitation to {email} was successfully sent! One more step has been given to your community to grow even more (:',

  'notification--subscription-cancel-success.title': 'Subscription canceled',
  'notification--subscription-cancel-success.message': 'Your signature has been canceled, and the amount of your donation will not be charged until you make a recurring donation.',

  'notification--report-download-in-progress-warning.title': 'Download in progress',
  'notification--report-download-in-progress-warning.message': 'The {filename} download is in progress. When everything is ready or, if you give some type of error, you will be notified. This process may take a few minutes. In any case, do not close the tab of your browser.',

  'notification--report-download-success.title': 'Yay! All ready (:',
  'notification--report-download-success.message': 'The {filename} download was successful.',

  'notification--report-download-error.title': 'Oops, something went wrong \\\\:',
  'notification--report-download-error.message': 'Something wrong happened when downloading {filename}. Can you try again? But if the error persists, you can talk to us by the support button there in the lower right corner. We are here to help you (:',

  'notify.community.check--dns--success.title': 'Yay!',
  'notify.community.check--dns--success.text': 'DNS servers are synchronized, you can now configure your email and other services, as well as choose the domain of your mobilization.',
  'notify.community.check--dns--failure.title': 'Ooops!',
  'notify.community.check--dns--failure.text': 'The DNS server change is still pending. You can try again in a few minutes.',
  'notify.community.add--dns-hosted-zone--failure.title': 'Ooops!',
  'notify.community.add--dns-hosted-zone--failure.text': 'There was an error on the server, make sure this domain was not already inserted.',

  'page--community-list.title': 'Hello {name},',
  'page--community-list.subtitle': 'Choose one of your communities',
  'page--community-list.or': 'or {link}',
  'page--community-list.new': 'Create a new community',

  'community.components--settings-menu.title': 'Community Settings',
  'community.components--settings-menu.tabs.info': 'Information',
  'community.components--settings-menu.tabs.mobilizers': 'Mobilizers',
  'community.components--settings-menu.tabs.mailchimp': 'Mailchimp',
  'community.components--settings-menu.tabs.recipient': 'Receiver',
  'community.components--settings-menu.tabs.metrics': 'Metrics',
  'community.components--settings-menu.tabs.domains': 'Domain Names',

  'community.components--domain-preview.li.domain.header': 'Community domain',

  'community.components--subdomain-preview-header.name': 'Name',
  'community.components--subdomain-preview-header.record-type': 'Kind',
  'community.components--subdomain-preview-header.value': 'Value',
  'community.components--domain.preview.label.domain': 'Community domain',
  'community.page--domain-list.header.dns-records': 'DNS Records',
  'community.page--domain-list.header.dns-server': 'DNS Servers',
  'community.page--domain-list.button.add-new-record': 'Add new record',
  'community.page--domain-list.button.add-new-domain': 'Add new domain',
  'community.page--domain-list.dialog.domain-confirm-message': 'Are you sure you want to remove the domain',
  'community.page--domain-list.dns-record-description.first-paragraph': 'DNS Servers are addresses used by domain registration organizations such as register.br or godaddy.com, to identify on which server the information about the registered domain is located.',
  'community.page--domain-list.dns-record-description.second-paragraph': 'Complete the domain activation by changing the DNS servers, where the domain was registered, to the addresses below:',
  'community.page--domain-list.dns-server-description': 'DNS Servers are addresses used by domain registration organizations such as {registroBr} or {goDaddy}, to identify on which server the information about the registered domain is located. Ask your questions {trilho}.',
  'community.page--domain-list.dns-server-description.trilho.link': 'on the help site',

  'community.components--subdomain.name.label': 'Name',
  'community.components--subdomain.name.placeholder': 'subdomain',
  'community.components--subdomain.record-type.label': 'Kind',
  'community.components--subdomain.value.label': 'Value',
  'community.components--subdomain.value.placeholder':
`redirecionamento.dominio.com
ou
servidor-01.dominio.com
servidor-02.dominio.com
servidor-03.dominio.com`,
  'community.components--subdomain.form.submit-button': 'Add',

  'page--community-domain.form.validation.required': 'Required field',

  'page--community-domain.header.info.title': 'Information',
  'page--community-domain.header.info.text': 'Below is the list of domains already registered. After changing and activating DNS servers, it becomes possible to publish a much faster mobilization, as well as manage registered external subdomains.',

  'page--community-domain.domain-preview.success-icon.title': 'Active DNS Servers',
  'page--community-domain.domain-preview.failure-icon.title': 'Waiting to change DNS servers',

  'page--community-domain.section--dns-hosted-zone.add': 'Add new domain',
  'page--community-domain.section--dns-hosted-zone.menu.subdomains': 'Subdomains',
  'page--community-domain.section--dns-hosted-zone.menu.remove': 'Remove domain',
  'page--community-domain.section--dns-hosted-zone.menu.remove.dialog.text': 'Are you sure you want to remove the domain {domainName}?',
  'page--community-domain.section--dns-hosted-zone.menu.check-dns': 'Test the connection',

  'page--community-domain.section--dns-records.menu.remove': 'Remove Subdomain',
  'page--community-domain.section--dns-records.menu.remove.dialog.text': 'Are you sure you want to remove the record {recordName}?',

  'ux.components--dialog.button.confirm.text': 'Confirm',
  'ux.components--dialog.button.cancel.text': 'Cancel',

  'page--community-domain-create.step-add.title': 'Enter the desired domain',
  'page--community-domain-create.step-add.form.domain-name.label': 'Domain of your community',
  'page--community-domain-create.step-add.form.domain-name.placeholder': 'Ex. Minhacomunidade.org',
  'page--community-domain-create.step-add.form.domain-name.validation.required': 'Domain is required.',
  'page--community-domain-create.step-add.form.domain-name.validation.invalid-domain-format': 'Invalid domain',
  'page--community-domain-create.step-add.form.cancel-button.text': 'Cancel',
  'page--community-domain-create.step-add.form.button.text': 'Add',

  'page--community-domain-create.step-dns-servers.step-title': 'Change the servers of your DNS provider',
  'page--community-domain-create.step-dns-servers.title': 'What are DNS Servers?',
  'page--community-domain-create.step-dns-servers.subtitle.first-paragraph': 'DNS Servers are addresses used by domain registration organizations such as {registroBr} or {goDaddy}, to identify which server the domain information is on.',
  'page--community-domain-create.step-dns-servers.subtitle.second-paragraph': 'Complete the domain activation by changing the DNS servers, where the domain was registered, to the addresses below:',
  'page--community-domain-create.step-dns-servers.change-later-button.text': 'Swap later',
  'page--community-domain-create.step-dns-servers.button.text': 'Continue',

  'page--community-domain-create.step-check.title': 'Test the connection',
  'page--community-domain-create.step-check.first-paragraph': 'Click the button below to check if everything is right.',
  'page--community-domain-create.step-check.second-paragraph': 'Warning: DNS change can take up to 48 hours to be propagated over the internet.',
  'page--community-domain-create.step-check.test-later-button.text': 'Test later',
  'page--community-domain-create.step-check.button.text': 'Test',

  'page--community-info.form.logo.label': 'Soon',
  'page--community-info.form.name.label': 'Name',
  'page--community-info.form.name.placeholder': 'Enter your community name',
  'page--community-info.form.name.validation.required': 'Enter community name',
  'page--community-info.form.description.label': 'Description',
  'page--community-info.form.description.placeholder': 'Enter a description for your community',
  'page--community-info.form.city.label': 'City',
  'page--community-info.form.city.validation.required': 'Tell us which city your community operates in',
  'page--community-info.form.custom-from-email.label': 'Notification reply email',
  'page--community-info.form.custom-from-email.helper-text': 'This email is used as the default sender of notifications.',
  'page--community-info.form.custom-from-email.placeholder': 'Ex: Name of sender <sender@provider.com>',
  'page--community-info.form.custom-from-email.validation.invalid-email-format': 'Response email out of standard format',

  'page--community-invite.info.title': 'Information',
  'page--community-invite.info.content.first-line': 'Invite new users to be part of your community, they will have access to the same information as you have.',
  'page--community-invite.info.content.second-line': 'Using the form below, you send the invitation by email.',
  'page--community-invite.form.email.label': 'Email',
  'page--community-invite.form.email.placeholder': 'Please enter an email to invite. Ex: mobilizer@email.com',
  'page--community-invite.form.submit-button.default': 'To invite',

  'page--community-invite.form.email.validation.required': 'Required',
  'page--community-invite.form.email.validation.invalid': 'Please enter a valid email address',

  'ux.components--settings-form.button.text': 'To save',
  'ux.components--settings-form.success-message': 'Data edited successfully',

  'page--community-mailchimp.warning.title': 'Attention',
  'page--community-mailchimp.warning.content.first-line': 'Configure integration with mailchimp so that it is possible to create segments of the users that interacted with your mobilization in it.',
  'page--community-mailchimp.warning.content.second-line': 'We adopted the following pattern in the name of the segments: M999P000, M999F000, M999D000 (M = Mobilization, P = Pressure, F = Form, D = Donation)',
  'page--community-mailchimp.form.api-key.label': 'Mailchimp API Key',
  'page--community-mailchimp.form.api-key.helper-text.title': 'Where to get this information?',
  'page--community-mailchimp.form.api-key.helper-text.step-01': 'After logging in as administrator in mailchimp, click on your username. A menu will appear, click the {accountStrong} option.',
  'page--community-mailchimp.form.api-key.helper-text.step-02': 'Follow the steps: {extrasStrong}> {apiKeysStrong}> {yourApiKeysStrong}> {createKeyStrong}',
  'page--community-mailchimp.form.api-key.helper-text.step-03': 'Now just paste the contents of {apiKeyStrong} into the field below.',
  'page--community-mailchimp.form.api-key.placeholder': 'Enter here the content of "API key"',
  'page--community-mailchimp.form.list-id.label': 'Mailchimp List ID',
  'page--community-mailchimp.form.list-id.helper-text.title': 'Where to get this information?',
  'page--community-mailchimp.form.list-id.helper-text.step-01': 'After logging in as administrator in mailchimp, click on your username. A menu will appear, click the {listStrong} option.',
  'page--community-mailchimp.form.list-id.helper-text.step-02': 'Select the corresponding list and follow the steps: {settingsStrong}> {listAndDefaultsStrong}',
  'page--community-mailchimp.form.list-id.helper-text.step-03': 'Now just paste in the field below the contents of the right column below the title {listIdStrong}',
  'page--community-mailchimp.form.list-id.placeholder': 'Enter here the "List ID"',
  'page--community-mailchimp.form.button.save': 'Save',
  'page--community-mailchimp.form.button.sync': 'Synchronize',

  'page--community-twilio.helper-text.title': 'Where to get this information?',
  'page--community-twilio.helper-text.twilio-login': 'Sign in to {link}',
  'page--community-twilio.helper-text.twilio-login.link': 'Twilio\'s website',

  'page--community-twilio.form.twilio-account-sid.label': 'Twilio Account SID',
  'page--community-twilio.form.twilio-auth-token.label': 'Twilio Auth Token',
  'page--community-twilio.form.twilio-auth-token.helper-text.eye-icon': 'Click the eye icon',
  'page--community-twilio.form.twilio-number.label': 'Twilio Number',
  'page--community-twilio.form.twilio-number.helper-text.menu-item': 'Click {strongPhoneNumber} ({strongHashtag} symbol in the left menu)',
  'page--community-twilio.form.twilio-number.helper-text.choice-number': 'Choose which number Twilio wants to use',

  'page--community-new.title': 'Create a community',
  'page--community-new.subtitle': 'BONDE communities are action groups that work together for a cause.',

  'page--community-new.form.name.label': 'Community name',
  'page--community-new.form.name.placeholder': 'Example: 90º Movement São Paulo',
  'page--community-new.form.city.label': 'Community town',
  'page--community-new.form.city.placeholder': 'Example: São Paulo',
  'page--community-new.form.submit-button.text.default': 'Create community',
  'page--community-new.form.submit-button.text.saving': 'Saving ...',

  'page--community-new.form.name.validation.required': 'Enter community name',
  'page--community-new.form.city.validation.required': 'Tell us which city your community operates in',

  'page--community-recipient.warning.title': 'Important',
  'page--community-recipient.warning.content.list.li-01': 'Report: Fill in your bank account below to automatically transfer the donations received by your community.',
  'page--community-recipient.warning.content.list.li-02': 'Attention 1: It is not possible to transfer a donation already received to a new bank account, always the active bank account will be used at the time of donation.',
  'page--community-recipient.warning.content.list.li-03': 'Attention 2: Donations are only available 31 days after the credit card transaction was created (29 calendar days + 2 business days) in the case of transactions with a portion and 2 business days after payment of the bank slip. If the transaction has 2 to 12 installments, the normal receipt will be as follows: first installment in 31 days, second in 61, third in 91, and so on.',

  'page--community-recipient.title': 'Scheduling of Looting',
  'page--community-recipient.form.transfer-interval.label': 'Recurrence',
  'page--community-recipient.form.transfer-interval.value.weekly': 'Weekly',
  'page--community-recipient.form.transfer-interval.value.monthly': 'Monthly',
  'page--community-recipient.form.transfer-day.label': 'Execution day',
  'page--community-recipient.form.transfer-day.weekly.mon': 'Monday',
  'page--community-recipient.form.transfer-day.weekly.tue': 'Tuesday',
  'page--community-recipient.form.transfer-day.weekly.wed': 'Fourth',
  'page--community-recipient.form.transfer-day.weekly.thu': 'Fifth',
  'page--community-recipient.form.transfer-day.weekly.fri': 'Friday',

  'page--community-recipient.section--account.title': 'Bank account',
  'page--community-recipient.form.bank-account-type.label': 'Account Type',
  'page--community-recipient.form.bank-account-type.value.checking-account': 'Chain',
  'page--community-recipient.form.bank-account-type.value.savings-account': 'Savings',
  'page--community-recipient.form.bank-code.label': 'Bank',
  'page--community-recipient.form.bank-code.value.default': 'Select the bank',
  'page--community-recipient.form.bank-agency.label': 'Agency',
  'page--community-recipient.form.bank-agency.placeholder': 'Enter numbers only',
  'page--community-recipient.form.bank-agency-dv.label': 'Digit',
  'page--community-recipient.form.bank-agency-dv.placeholder': 'Ex: 0',
  'page--community-recipient.form.bank-account.label': 'Account',
  'page--community-recipient.form.bank-account.plcaeholder': 'Enter numbers only',
  'page--community-recipient.form.bank-account-dv.label': 'Digit',
  'page--community-recipient.form.bank-account-dv.plcaeholder': 'Ex: 00',
  'page--community-recipient.form.bank-legal-name.label': 'Name / Company Name',
  'page--community-recipient.form.bank-legal-name.placeholder': 'Ex: My Sampa',
  'page--community-recipient.form.bank-document-number.label': 'CPF / CNPJ',
  'page--community-recipient.form.bank-document-number.placeholder': 'Enter numbers only',

  'page--community-recipient.form.validation.required': 'Required field',
  'page--community-recipient.form.bank-agency.validation.max-length': 'Must contain no more than 5 digits',
  'page--community-recipient.form.bank-agency-dv.validation.length': 'Must contain only 1 digit',
  'page--community-recipient.form.bank-account.validation.max-length': 'Must contain a maximum of 13 digits',
  'page--community-recipient.form.bank-account-dv.validation.max-length': 'Must contain a maximum of 2 characters',
  'page--community-recipient.form.bank-document-number.validation.cnpj-length': 'CNPJ must contain 14 digits',
  'page--community-recipient.form.bank-document-number.validation.cpf-length': 'CPF must contain 11 digits',
  'page--community-recipient.form.bank-document-number.validation.invalid-cpf-format': 'Invalid CPF',
  'page--community-recipient.form.bank-document-number.validation.invalid-cnpj-format': 'Invalid CNPJ',

  'c--metrics.unique.activists.title': 'ACTIVISTS',
  'c--metrics.unique.activists.subtitle': 'Total of unique activists',
  'c--metrics.total.active.activists.title': 'ACTIVISTS',
  'c--metrics.total.active.activists.subtitle': 'Total active activists {br} in the past 90 days',

  'c--metrics.total.pressure.actions.title': 'ACTIONS',
  'c--metrics.total.pressure.actions.subtitle': 'Total pressure actions {br} in the last 90 days',
  'c--metrics.total.subscriptions.actions.title': 'ACTIONS',
  'c--metrics.total.subscriptions.actions.subtitle': 'Total subscription actions {br} over the last 90 days',

  'c--metrics.total.unique.donations.title': 'DONATIONS',
  'c--metrics.total.unique.donations.subtitle': 'Total value of single donations confirmed in the last 30 days',
  'c--metrics.total.recurrent.donations.title': 'DONATIONS',
  'c--metrics.total.recurrent.donations.subtitle': 'Total value of recurring donations confirmed in the last 30 days',
  'c--metrics.total.unique-and-recurrent.donations.title': 'DONATIONS',
  'c--metrics.total.unique-and-recurrent.donations.subtitle': 'Total value of single and recurring donations so far {br} (confirmed / awaiting payment)',

  'c--metrics.total.donations.charged-back-amount': 'Total amount returned for donations collected so far:',
  'c--metrics.total.donations.refunded-amount': 'Total repayment value of donations collected so far:',
  'c--metrics.total.donations.refused-amount': 'Total repayment value of donations collected so far:',

  'page--community-report.title.metrics': 'Metrics',
  'page--community-report.title.reports': 'Reports',

  'page--community-report.section-button.donation.title': 'DONATIONS REPORT',
  'page--community-report.section-button.donation.helper-text': 'Click the button below to download the community donation report.',
  'page--community-report.section-button.donation.text': 'Download',

  'page--community-report.section-button.actions.title': 'REPORT OF SHARES',
  'page--community-report.section-button.actions.helper-text': 'Click the button below to download the report of actions taken in the community.',
  'page--community-report.section-button.actions.text': 'Download',

  'page--community-report.section-button.activists.title': 'ACTIVIST REPORT',
  'page--community-report.section-button.activists.helper-text': 'Click the button below to download the community activists report.',
  'page--community-report.section-button.activists.text': 'Download',

  'page--community-report.section-button.recurring-donors.title': 'RECORRING DONORS REPORT',
  'page--community-report.section-button.recurring-donors.helper-text': 'Click the button below to download the report from recurring community donors.',
  'page--community-report.section-button.recurring-donors.text': 'Download',

  'components.navigation--sidebar.community-settings.item.mobilizations': 'Mobilizations',
  'components.navigation--sidebar.community-settings.item.info': 'Community',
  'components.navigation--sidebar.community-settings.item.metrics': 'Metrics',
  'components.navigation--sidebar.community-settings.item.domains': 'Domain Names',

  'components.navigation--sidebar.mobilization-settings.item.launch': 'PUBLISH BONDE',
  'components.navigation--sidebar.mobilization-settings.item.launched': 'Public BONDE',
  'components.navigation--sidebar.mobilization-settings.item.edit': 'Edit mobilization',
  'components.navigation--sidebar.mobilization-settings.item.add-block': 'Add Content',
  'components.navigation--sidebar.mobilization-settings.item.open-at-new-tab': 'View in new tab',
  'components.navigation--sidebar.mobilization-settings.item.config': 'Settings',

  'components.navigation--sidebar.footer.account': 'My account',
  'components.navigation--sidebar.footer.sign-out': 'Get out',

  'components.navigation--sidenav.config': 'Settings',
  'components.navigation--sidenav.change-community': 'To exchange',

  'page--mobilizations-list.page-header.title': 'Your Mobilizations',
  'page--mobilizations-list.more-menu-action.open': 'Open page',
  'page--mobilizations-list.more-menu-action.create-template': 'Create template',

  'mobilizations.components--page-header.button.text': 'New mobilization',
  'mobilizations.components--page-header.tabs.actives': 'Active',
  'mobilizations.components--page-header.tabs.templates': 'Templates',

  'mobilizations.components--list.items.name.header.text': 'Name',

  'mobilizations.components--list.items.created-at.header.text': 'Created in',

  'mobilizations.components--list.items.users.header.text': 'Users',

  'mobilizations.components--list.items.fund-raising.header.text': 'Fund Raising',
  'mobilizations.components--list.items.fund-raising.currency': 'R$',

  'mobilizations.components--list.items.copy-number.header.text': 'Copies',

  'mobilizations.components--page-tab-layout.title': 'New mobilization',
  'mobilizations.components--page-tab-layout.tabs.goal': 'Goal',
  'mobilizations.components--page-tab-layout.tabs.templates': 'Templates',

  'page--mobilizations-new.title': 'What is the purpose of your mobilization?',
  'page--mobilizations-new.footer': 'Stay calm @ you can edit later if you feel it necessary.',

  'mobilizations.components--basics-form.name.label': 'Name',
  'mobilizations.components--basics-form.name.placeholder': 'Ex: For the creation of a police station for missing persons',
  'mobilizations.components--basics-form.name.validation.required': 'Enter the name of the mobilization',
  'mobilizations.components--basics-form.name.validation.max-length': 'Your title is too long!',
  'mobilizations.components--basics-form.slug.label': 'Unique Identifier',
  'mobilizations.components--basics-form.slug.helper-text': 'The value of this field is used to refer to mobilization in the BONDE domain, for example: {example}',
  'mobilizations.components--basics-form.slug.validation.required': 'Enter the unique identifier of the mobilization',
  'mobilizations.components--basics-form.slug.validation.max-length': 'Your unique identifier is too long!',
  'mobilizations.components--basics-form.goal.label': 'Goal',
  'mobilizations.components--basics-form.goal.placeholder': 'Make a short text that will motivate others to join in your mobilization. You can change this text later.',
  'mobilizations.components--basics-form.goal.validation.required': 'Enter the purpose of the mobilization',
  'mobilizations.components--basics-form.goal.validation.max-length': 'The character limit has been reached.',

  'components--control-buttons.cancel': 'Come back',
  'components--control-buttons.input.value.default': 'Continue',
  'components--control-buttons.input.value.saving': 'Saving ...',

  'page--mobilizations.templates-choose.title': 'How do you want to start?',
  'page--mobilizations.templates-choose.browsable-list-item.blank': 'Create mobilization from scratch',
  'page--mobilizations.templates-choose.browsable-list-item.templates-custom': 'My templates',
  'page--mobilizations.templates-choose.browsable-list-item.templates-global': 'Global Templates',

  'templates.components--selectable-list.filterable-search-bar.placeholder': 'Search for a template',
  'templates.components--selectable-list.empty-list-text': 'There is no template with this name',
  'templates.components--selectable-list.button.back': 'Come back',
  'templates.components--selectable-list.button.next': 'Continue',

  'page--mobilizations.templates-choose-custom.title': 'My Templates',

  'page--mobilizations.templates-choose-global.title': 'Global Templates',

  'mobilizations.components--settings-menu.title': 'Set up your mobilization',
  'mobilizations.components--settings-menu.tabs.info': 'Basic information',
  'mobilizations.components--settings-menu.tabs.sharing': 'Sharing',
  'mobilizations.components--settings-menu.tabs.metrics': 'Metrics',
  'mobilizations.components--settings-menu.tabs.domain': 'Domain',

  'page--mobilizations-analytics.first-paragraph': 'To track the results of your mobilization, you need to set up an account in Google Analytics.',
  'page--mobilizations-analytics.second-paragraph': 'Follow the steps below:',
  'page--mobilizations-analytics.ol.create-analytics-account': ' Create an account in Google Analytics {analyticsLink}',
  'page--mobilizations-analytics.ol.create-analytics-account.link': 'clicking here',
  'page--mobilizations-analytics.ol.keep-up-with': 'Get your Tracking ID in Google Analytics. It is a code that always starts with the letters UA, which you will see after creating your account there.',
  'page--mobilizations-analytics.ol.paste-ga-code': 'Copy the tracking ID and paste in the field below:',
  'page--mobilizations-analytics.ol.form.ga-code.label': 'Google Analytics ID',
  'page--mobilizations-analytics.ol.done': 'Ready! You can now track your mobilization statistics in Google Analytics!',

  'page--mobilizations-analytics.ol.form.ga-code.validation.invalid.ga-code.format': 'Please enter a valid ID',

  'page--block-create.title': 'Add a content block',
  'page--block-create.tabs.blank-blocks': 'Blocks in white',
  'page--block-create.helper-text': 'Blocks will be added to the bottom of your page, but you can change them to order at any time',
  'page--block-create.type.label': 'Type of block',
  'page--block-create.background.label': 'Bottom',
  'page--block-create.background.image.placeholder.text': 'Select background image',
  'page--block-create.button-add.text': 'Add',

  'page--mobilizations-domain.form-domain.success-message': 'Successfully saved domain data',

  'mobilizations.components--form-domain.helper-text-first-line': 'We are almost there, before publishing your mobilization you must choose the url that will be used for publication.',
  'mobilizations.components--form-domain.helper-text-second-line': 'Already registered a domain in the community? Select below which of the options best fits.',
  'mobilizations.components--form-domain.helper-text-third-line': 'Want to register a new domain? {link}.',
  'mobilizations.components--form-domain.helper-text-third-line.link': 'click here',

  'mobilizations.components--form-domain.create-domain-text.first-line': 'Oops, you do not have a domain configured in your community yet. If you want to track, {link}.',
  'mobilizations.components--form-domain.create-domain-text.first-line.link': 'click here',
  'mobilizations.components--form-domain.create-domain-text.second-line': 'Otherwise, you can use an external domain below to configure the address of your mobilization.',

  'mobilizations.components--form-domain.basic.header-toggle.use-existing-domain': 'I want to use a new sub-domain',
  'mobilizations.components--form-domain.basic.helper-text': 'Fill in the subdomain below and choose the domain you want to configure as your mobilization address',
  'mobilizations.components--form-domain.basic.form.subdomain.label': 'Subdomain',
  'mobilizations.components--form-domain.basic.form.subdomain.placeholder': 'mobilization-name',
  'mobilizations.components--form-domain.basic.form.domain.label': 'Primary Domain',
  'mobilizations.components--form-domain.basic.form.domain.button.choice': 'Choice...',

  'mobilizations.components--form-domain.root.header-toggle.use-root-domain': 'I want to use a primary domain registered in the community',
  'mobilizations.components--form-domain.root.helper-text': 'Choose the domain you want to configure as your mobilization address',

  'mobilizations.components--form-domain.advanced.header-toggle': 'I want to direct to an external domain',
  'mobilizations.components--form-domain.advanced.helper-text': 'If you want to use a domain that you have purchased but are not registered in your community here, you can do so. For example, if you have already purchased www.mydomain.com you can use it for this BONDE. Too bad, right? Fill in the field below and follow the guidelines:',
  'mobilizations.components--form-domain.advanced.form.external-domain.label': 'Custom domain',
  'mobilizations.components--form-domain.advanced.form.external-domain.placeholder': 'mydomain.com',

  'mobilizations.components--form-domain.cname-table.helper-text': '{strong}: You will need to configure this domain on your registration server so that the address is redirected to your mobilization page. For this, you will need this information down here, write it down:',
  'mobilizations.components--form-domain.cname-table.helper-text.strong': 'Do not forget',
  'mobilizations.components--form-domain.cname-table.header.name': 'Name',
  'mobilizations.components--form-domain.cname-table.header.record-type': 'Kind',
  'mobilizations.components--form-domain.cname-table.header.data': 'Data',
  'mobilizations.components--form-domain.cname-table.footer.helper-text': 'If you have any questions, take a look at the "Configuring your domain on BONDE" topic in our tutorial, {link}.',
  'mobilizations.components--form-domain.cname-table.footer.helper-text.link': 'Trilho',

  'mobilizations.components--form-domain.validation.required': 'Required field',
  'mobilizations.components--form-domain.validation.subdomain.required': 'Required to fill subdomain',
  'mobilizations.components--form-domain.validation.subdomain.required-domain': 'Required to complete main domain',
  'mobilizations.components--form-domain.validation.subdomain.invalid': 'Please enter a valid subdomain',
  'mobilizations.components--form-domain.validation.external-domain.invalid': 'Enter a valid domain',

  'mobrender.components--mobilization.footer.slogan': 'Feito pra causar. Feito com',
  'mobrender.components--mobilization.add-block-content': 'Add content block',

  'mobrender.components--block-config-menu.item.duplicate': 'Duplicate block',
  'mobrender.components--block-config-menu.item.change-background': 'Change Background',
  'mobrender.components--block-config-menu.item.toggle-visibility.show': 'Show',
  'mobrender.components--block-config-menu.item.toggle-visibility.hide': 'To hide',
  'mobrender.components--block-config-menu.item.remove': 'To remove',
  'mobrender.components--block-config-menu.item.remove.confirm': 'Are you sure you want to remove this block?',
  'mobrender.components--block-config-menu.item.move-up': 'Move up',
  'mobrender.components--block-config-menu.item.move-down': 'Move down',

  'mobrender.components--block-change-background.button.save': 'To save',
  'mobrender.components--block-change-background.button.cancel': 'Cancel',

  'mobrender.components--block.hidden-tag': 'Hidden',

  'components.navigation--navbar-edition-wrapper.block': 'Block {position}',

  'mobilizations.components--form-share.facebook.title': 'Share of Facebook',
  'mobilizations.components--form-share.facebook.helper-text': 'Set up the post that will be posted to Facebook whenever someone shares the action. It is important that these texts are captivating and short so they do not appear cut. :)',
  'mobilizations.components--form-share.facebook.fb.image.helper-text': 'Use images with at least 1200x630 pixels for best viewing on high resolution devices. At a minimum, you should use images that are 600x315 pixels to display posts on the linked page with larger images. The maximum size of the images is 8 MB.',
  'mobilizations.components--form-share.facebook.fb.image.link': 'know more',
  'mobilizations.components--form-share.facebook.form.share-image.label': 'Image',
  'mobilizations.components--form-share.facebook.form.share-title.label': 'Post title',
  'mobilizations.components--form-share.facebook.form.share-title.placeholder': 'A direct title that gives the idea of ​​its mobilization',
  'mobilizations.components--form-share.facebook.form.share-description.label': 'Post Subtitle',
  'mobilizations.components--form-share.facebook.form.share-description.placeholder': 'Complete the title information and call the reader to mobilize',

  'mobilizations.components--form-share.twitter.title': 'Share Twitter',
  'mobilizations.components--form-share.twitter.helper-text': 'Set up the message that will be published on Twitter whenever someone shares your mobilization.',
  'mobilizations.components--form-share.twitter.form.share-text.label': 'Tweet text',
  'mobilizations.components--form-share.twitter.form.share-text.placeholder': 'Insert a phrase and call the reader to the mobilization',

  'page--mobilizations-launch.title': 'Launching your mobilization',
  'page--mobilizations-launch.steps.form-domain.title': 'Set the mobilization address',
  'page--mobilizations-launch.steps.form-share.title': 'Configure sharing information',
  'page--mobilizations-launch.steps.done.title': 'Your BONDE is ready!',
  'page--mobilizations-launch.steps.done.helper-text': 'In a new tab, enter the address you registered in the mobilization to make sure it is already on the air. If it\'s not already, check if you have successfully registered the domains. Everything is alright? So just wait for it to spread over the internet!',
  'page--mobilizations-launch.steps.done.button.open': 'View Mobilization',
  'page--mobilizations-launch.button.saving': 'Saving ...',
  'page--mobilizations-launch.button.launch': 'Launch mobilization',
  'page--mobilizations-launch.button.next': 'Continue',
  'page--mobilizations-launch.form-share.validation.required': 'Required',

  'page--mobilizations-launch-end.heading.all-done': 'All ready?',
  'page--mobilizations-launch-end.heading.just-launch': 'Now, just throw it and tell everyone!',
  'page--mobilizations-launch-end.title': 'It\'s time',
  'page--mobilizations-launch-end.button': 'Launch mobilization',

  'page--templates-list.header.title': 'Your templates',
  'page--templates-list.empty-list.no-template': 'No template created.',
  'page--templates-list.empty-list.create-one': 'Create from a mobilization.',
  'page--templates-list.empty-list.mobilization-list': 'List of mobilizations',
  'page--templates-list.more-menu-action.remove.text': 'To remove',
  'page--templates-list.more-menu-action.remove.confirm': 'Are you sure you want to remove this template? By confirming, you can not undo this action.',

  'page--templates-create.header.title': 'Create a template from the mobilization',
  'page--templates-create.form.name.label': 'Name of your template',
  'page--templates-create.form.name.placeholder': 'For the creation of a police station for the disappeared',
  'page--templates-create.form.goal.label': 'Description',
  'page--templates-create.form.goal.placeholder': 'Make a short text that can motivate others to join in your mobilization. You can change this text later.',

  'page--templates-create.form.validation.required': 'Required field',

  'donation.components--settings-menu.title': 'Set up your donation box',
  'donation.components--settings-menu.tabs.adjusts': 'Settings',
  'donation.components--settings-menu.tabs.autofire': 'Message thanks',
  'donation.components--settings-menu.tabs.post-action': 'Post-Donation',

  'page--donation-widget.form.donation-value-title': 'Click to set this value by default.',
  'page--donation-widget.form.submit-button': 'To save',
  'page--donation-widget.form.success-message': 'Donation form set up successfully!',
  'page--donation-widget.form.payment-type.label': 'Type of donation',
  'page--donation-widget.form.payment-type.unique': 'Unique',
  'page--donation-widget.form.payment-type.recurring': 'Recurrent',
  'page--donation-widget.form.payment-type.users-choice': 'User chooses',
  'page--donation-widget.form.payment-interval.label': 'Recurrence interval',
  'page--donation-widget.form.payment-interval.monthly': 'Monthly',
  'page--donation-widget.form.payment-interval.semiannually': 'Semester',
  'page--donation-widget.form.payment-interval.annually': 'Yearly',
  'page--donation-widget.form.goal.label': 'Campaign goal',
  'page--donation-widget.form.goal.placeholder': 'Ex .: 50000',
  'page--donation-widget.goal-date-limit.label': 'Deadline for collection',
  'page--donation-widget.goal-date-limit.placeholder': 'Ex .: DD / MM / YYYY',
  'page--donation-widget.form.donation-default-value.label': 'Donation values',
  'page--donation-widget.form.donation-default-value.helper-text': 'You can have up to 5 values ​​per donation block. Fill in only with integers (Ex: 50)',
  'page--donation-widget.form.default-value-01.label': 'Value 1',
  'page--donation-widget.form.default-value-01.placeholder': 'R$ 20',
  'page--donation-widget.form.default-value-02.label': 'Value 2',
  'page--donation-widget.form.default-value-02.placeholder': 'R$ 50',
  'page--donation-widget.form.default-value-03.label': 'Value 3',
  'page--donation-widget.form.default-value-03.placeholder': 'R$ 100',
  'page--donation-widget.form.default-value-04.label': 'Value 4',
  'page--donation-widget.form.default-value-04.placeholder': 'R$ 200',
  'page--donation-widget.form.default-value-05.label': 'Value 5',
  'page--donation-widget.form.default-value-05.placeholder': 'R$ 500',
  'page--donation-widget.form.default-value.radio.text': 'Default',
  'page--donation-widget.form.default-value.helper-text': '* all values ​​are in reais',
  'page--donation-widget.form.donation-title.label': 'Donation box title',
  'page--donation-widget.form.donation-title.placeholder': 'Eg: Choose a value and contribute now!',
  'page--donation-widget.form.main-color.label': 'Donation Box Color',
  'page--donation-widget.form.main-color.helper-text': 'Select the color in the box below or enter the value in hex, for example: # DC3DCE.',
  'page--donation-widget.form.button-text.label': 'Confirmation button text',
  'page--donation-widget.form.button-text.placeholder': 'Eg Donate now!',
  'page--donation-widget.form.payment-method.label': 'Enable Pay Per Ticket?',
  'page--donation-widget.form.payment-method.helper-text': 'Pay per ticket is only available for one-time donations. Each ticket paid will have an additional cost of R $ 3.00.',
  'page--donation-widget.form.payment-method.radio.yes': 'Yes',
  'page--donation-widget.form.payment-method.radio.no': 'No',
  'page--donation-widget.form.bank-account.label': 'Bank account',
  'page--donation-widget.form.bank-account.helper-text': 'This campaign is associated with the bank account that is registered in that community\'s settings;)',

  'page--donation-widget.form.validation.invalid': 'Invalid.',
  'page--donation-widget.form.validation.button-text.required': 'Enter button text',
  'page--donation-widget.form.validation.button-text.max-length': 'The character limit has been reached.',
  'page--donation-widget.form.validation.goal.format': 'Enter the value this way, ó: 1000',
  'page--donation-widget.form.validation.goal-date-limit.format': 'Enter the date like this, ó: DD / MM / YYYY',
  'page--donation-widget.form.validation.goal-date-limit.invalid-date': 'Invalid date.',
  'page--donation-widget.form.validation.goal-date-limit.date-must-be-higher': 'Goal date can not be in the past.',

  'widgets.components--form-autofire.form.submit-button': 'To save',
  'widgets.components--form-autofire.form.success-message': 'Thanks message successfully configured!',
  'widgets.components--form-autofire.form.sender-name.label': 'Sender\'s name',
  'widgets.components--form-autofire.form.sender-name.placeholder': 'Set the name that will appear in the sent message.',
  'widgets.components--form-autofire.form.sender-email.label': 'Sender email',
  'widgets.components--form-autofire.form.sender-email.placeholder': 'Set the e-mail that will appear in the sent message.',
  'widgets.components--form-autofire.form.sender-email.validation.invalid-email-format': 'Enter an invalid email address',
  'widgets.components--form-autofire.form.email-subject.label': 'Email subject',
  'widgets.components--form-autofire.form.email-subject.placeholder': 'Set the subject that will appear in the sent message.',
  'widgets.components--form-autofire.form.email-text.label': 'Thank you email',
  'widgets.components--form-autofire.form.email-text.placeholder': 'Ex: Thank you for betting on the force of collective networking action. Your participation is very important and we need your help now so that more people can collaborate with this mobilization. Share on your networks by clicking one of the links below. A hug.',

  'widgets.components--form-finish-message.success-message': 'Form saved successfully!',
  'widgets.components--form-finish-message.type.label': 'Type of message',
  'widgets.components--form-finish-message.type.radio.share': 'To share',
  'widgets.components--form-finish-message.type.radio.custom': 'Customize',
  'widgets.components--form-finish-message.type.radio.donation-recorrent': 'Donation recorrent',
  'widgets.components--form-finish-message.type.radio.donation-recurrent.helper-text': 'The donor is given the option to continue donating every month and become a recurring donor. Then, you see the default sharing message',
  'widgets.components--form-finish-message.type.validation.required': 'No message type selected',
  'widgets.components--form-finish-message.share.whatsapp-text.label': 'WhatsApp text',
  'widgets.components--form-finish-message.share.whatsapp-text.placeholder': 'Make a short text that can motivate others to join in your mobilization. You can change this text later.',
  'widgets.components--form-finish-message.preview.label': 'Preview',
  'widgets.components--form-finish-message.custom.message.default': 'Click here to edit...',

  'share.components--tell-a-friend.text': 'Now share it with your friends!',

  'share.components--facebook-share-button.text': 'Share on Facebook',

  'share.components--twitter-share-button.text': 'Share on Twitter',

  'share.components--whatsapp-share-button.text': 'Share on WhatsApp',

  'page--donation-widget-finish.form.success-message': 'Post-donation form saved successfully!',

  'donation.components--tell-a-friend.message': 'Yay, donation registered! Is your donation via ticket? Check your email.',

  'widgets.config--content.label': 'Text',
  'widgets.config--content.default': 'Click here to edit...',
  'widgets.config--form.label': 'Form',
  'widgets.config--form.default': 'Thank you for betting on the strength of collective networking action. Your participation is very important and we need your help now so that more people can collaborate with this mobilization. Share on your networks by clicking one of the links below. \n\nA hug',
  'widgets.config--pressure.label': 'Pressure',
  'widgets.config--pressure.default.title': 'Send an email to anyone who can make this decision',
  'widgets.config--pressure.default.button-text': 'Send Email',
  'widgets.config--donation.label': 'Donation',

  'widgets.components--donation.default.button-text': 'Give Now',
  'widgets.components--donation.default.title-text': 'Click to set up your donation block',
  'widgets.components--donation.period-label-options.month': 'month',
  'widgets.components--donation.period-label-options.halfyear': 'half',
  'widgets.components--donation.period-label-options.year': 'year',
  'widgets.components--donation.users-choice.recurring': 'Support all {periodLabelCurrent}',
  'widgets.components--donation.users-choice.unique': 'Single donation',
  'widgets.components--donation.reattempt.message.title': 'Oops!',
  'widgets.components--donation.reattempt.message.text.line-01': 'Something wrong happened with your donation. ):',
  'widgets.components--donation.reattempt.message.text.line-02': 'Click the button below to try again.',
  'widgets.components--donation.reattempt.message.button.text': 'Retry',
  'widgets.components--donation.progress-bar.collected': 'collected',
  'widgets.components--donation.progress-bar.supports': `
    {totalDonations} {totalDonations, plural,
      one {support}
      other {supports}
    }
  `,
  'widgets.components--donation.progress-bar.goal': 'Goal:',
  'widgets.components--donation.progress-bar.date.last-day': 'last day!',
  'widgets.components--donation.progress-bar.date.last-days': 'last days!',
  'widgets.components--donation.progress-bar.date.last-week': 'last week!',
  'widgets.components--donation.progress-bar.date.remaining': `
    remaining {goalDateRemaining} {goalDateRemaining, plural,
      one {day}
      other {days}
    }
  `,

  'form-widget.components--settings-menu.title': 'Set up your action form',
  'form-widget.components--settings-menu.items.fields': 'Form fields',
  'form-widget.components--settings-menu.items.adjusts': 'Settings',
  'form-widget.components--settings-menu.items.autofire': 'Message thanks',
  'form-widget.components--settings-menu.items.report': 'Report',
  'form-widget.components--settings-menu.items.post-action': 'Post-enrollment',

  'page--form-widget.form.success-message': 'Form configured successfully!',
  'page--form-widget.form.widget-title.label': 'Form title',
  'page--form-widget.form.widget-title.placeholder': 'Ex: Fill out the form below to sign the petition.',
  'page--form-widget.form.button-text.label': 'Button',
  'page--form-widget.form.button-text.placeholder': 'Set the text of the form\'s confirmation button.',
  'page--form-widget.form.counter-text.label': 'Counter',
  'page--form-widget.form.counter-text.placeholder': 'Set the text that will stand next to the number of people who acted.',

  'widgets.components--data-export.formated-export-at': '{date} at {time}',
  'widgets.components--data-export.loading.message': 'Please wait while we are processing ...',
  'widgets.components--data-export.exported.message': 'Last export: {formatedExportAt}.',
  'widgets.components--data-export.export.label': 'Export',
  'widgets.components--data-export.export.helper-text': 'Click the button below to download the full form report in excel format.',
  'widgets.components--data-export.export.button': 'Click to download the complete worksheet.',

  'action--async-widget-data-export.no-data': 'No data were found to be exported',

  'c--content-widget.delete-widget.confirm.message': 'Do you want to remove the widget?',

  'c--editor-slate.button-save.text': 'Save',
  'c--editor-slate.button-cancel.text': 'Cancel',

  'page--form-widget-fields.add-button': 'Add a field',
  'page--form-widget-fields.helper-text.still-empty': 'Your form does not have any fields yet. Click below to start adding fields.',
  'page--form-widget-fields.helper-text.manage-fields': 'Add, remove, edit, and sort the form fields according to the needs of your action.',

  'form-widget.components--form.default.title-text': 'Click to set up your form ...',
  'form-widget.components--form.default.button-text': 'To send',
  'form-widget.components--form.default.counter-suffix': 'subscriptions',

  'form-widget.components--input.click-to-edit': 'Click to edit',
  'form-widget.components--input.field-dropdown.options.default': 'Select ...',
  'form-widget.components--input.field-greetings.title': 'Success message changed to:',

  'form-widget.components--input-form.handle-remove.confirm': 'Are you sure you want to remove this field?',
  'form-widget.components--input-form.handle-overlay-click.confirm': 'When leaving without saving you will lose your modifications. Do you want to quit without saving?',
  'form-widget.components--input-form.field-title.label': 'Field Title',
  'form-widget.components--input-form.field-title.placeholder': 'Ex: Email',
  'form-widget.components--input-form.field-helper-text.label': 'Help text',
  'form-widget.components--input-form.field-helper-text.placeholder': 'Ex: Insert your email here',
  'form-widget.components--input-form.field-type.label': 'Field Type',
  'form-widget.components--input-form.field-type.options.text': 'Text',
  'form-widget.components--input-form.field-type.options.email': 'Email',
  'form-widget.components--input-form.field-type.options.number': 'Number',
  'form-widget.components--input-form.field-type.options.dropdown': 'Dropdown ★',
  'form-widget.components--input-form.field-type.options.greetings': 'Greeting ★',
  'form-widget.components--input-form.field-required.label': 'Required',
  'form-widget.components--input-form.field-required.radio.yes.label': 'Yes',
  'form-widget.components--input-form.field-required.radio.no.label': 'No',
  'form-widget.components--input-form.button-move-up': 'Move up',
  'form-widget.components--input-form.button-move-down': 'Move down',
  'form-widget.components--input-form.button-remove': 'To remove',
  'form-widget.components--input-form.button-cancel': 'Cancel',
  'form-widget.components--input-form.button-save.saving': 'Saving ...',
  'form-widget.components--input-form.button-save.default': 'To save',

  'mobrender.components--widget-overlay.button.edit.title': 'To edit',
  'mobrender.components--widget-overlay.button.remove.title': 'To remove',

  'page--form-widget-finish.success-message': 'Post-enrollment form saved successfully!',

  'form-widget.components--tell-a-friend.message': 'Form submitted successfully!',

  'pressure-widget.components--pressure-form.validation.required': 'Required field',
  'pressure-widget.components--pressure-form.email.validation.invalid-email-format': 'Invalid email',
  'pressure-widget.components--pressure-form.email.validation.sender-is-target': 'The email you are trying to use is one of the targets of the mobilization.',
  'pressure-widget.components--pressure-form.phone.validation.ddd': 'Enter the country code and the two-digit DDD. Ex: +5511',
  'pressure-widget.components--pressure-form.phone.validation.invalid': 'Invalid phone number',
  'pressure-widget.components--pressure-form.phone.validation.caller-is-target': 'The phone you are trying to use is one of the targets of the mobilization.',
  'pressure-widget.components--pressure-form.email.label': 'Email',
  'pressure-widget.components--pressure-form.email.placeholder': 'Enter your email',
  'pressure-widget.components--pressure-form.phone.label': 'Phone',
  'pressure-widget.components--pressure-form.phone.placeholder': 'Insert your phone. Ex: +5511987654321',
  'pressure-widget.components--pressure-form.name.label': 'Name',
  'pressure-widget.components--pressure-form.name.placeholder': 'Enter your name',
  'pressure-widget.components--pressure-form.lastname.label': 'Last name',
  'pressure-widget.components--pressure-form.lastname.placeholder': 'Please enter your last name',
  'pressure-widget.components--pressure-form.city.label': 'City',
  'pressure-widget.components--pressure-form.city.placeholder': 'Enter your city',
  'pressure-widget.components--pressure-form.subject.label': 'Subject matter',
  'pressure-widget.components--pressure-form.body.label': 'Email body',
  'pressure-widget.components--pressure-form.phone-calls.ringing': 'Call in progress',
  'pressure-widget.components--pressure-form.phone-calls.retry': 'To reenact',
  'pressure-widget.components--pressure-form.phone-calls.call': 'To reenact',
  'pressure-widget.components--pressure-form.phone.how-it-works.title': 'How it works?',
  'pressure-widget.components--pressure-form.phone.how-it-works.list-item-01': 'We\'re calling your target.',
  'pressure-widget.components--pressure-form.phone.how-it-works.list-item-02': 'As soon as someone picks up from there, we\'ll call you',
  'pressure-widget.components--pressure-form.phone.how-it-works.list-item-03': 'When you answer, we connect the calls',
  'pressure-widget.components--pressure-form.phone.how-it-works.list-item-04': 'Now it\'s up to you!',
  'pressure-widget.components--pressure-form.phone.finish-and-share': 'Close and Share',

  'pressure-widget.components--settings-menu.title': 'Set up your pressure form',
  'pressure-widget.components--settings-menu.items.form': 'Form',
  'pressure-widget.components--settings-menu.items.pressure-email': 'Email to target',
  'pressure-widget.components--settings-menu.items.autofire': 'Thank you message',
  'pressure-widget.components--settings-menu.items.post-action': 'Post-pressure',

  'c--pressure-count.text.default': 'pressures',

  'page--pressure-widget.success-message': 'Pressure form configured successfully!',
  'page--pressure-widget.form.title-text.label': 'Form title',
  'page--pressure-widget.form.title-text.placeholder': 'Send an email to anyone who can make this decision',
  'page--pressure-widget.form.button-text.label': 'Button text',
  'page--pressure-widget.form.button-text.placeholder': 'Send Email',
  'page--pressure-widget.form.main-color.label': 'Form Color',
  'page--pressure-widget.form.show-counter.label': 'Show pressure counter',
  'page--pressure-widget.form.show-counter.radio.yes.label': 'Yes',
  'page--pressure-widget.form.show-counter.radio.no.label': 'No',
  'page--pressure-widget.form.counter-text.label': 'Counter Text',
  'page--pressure-widget.form.counter-text.placeholder': 'pressures',
  'page--pressure-widget.form.show-city-field.label': 'Show City Field',
  'page--pressure-widget.form.show-city-field.radio.yes.label': 'Yes',
  'page--pressure-widget.form.show-city-field.radio.no.label': 'No',

  'page--pressure-widget.form.validation.title-text.required': 'Enter a title for the form',
  'page--pressure-widget.form.validation.button-text.required': 'Enter text for the button',

  'widgets.components--input-tag.tags.label': 'Targets ({targetsCount})',
  'widgets.components--input-tag.insert-tag.placeholder': 'First target\'s name <first@target.com>\nSecond target\'s name <second@target.com>\nThird target\'s name <third@target.com>\nFourth target\'s name <fourth@target.com>\nFifth target\'s name <fifth@target.com>\n...',
  'widgets.components--input-tag.button.remove-all': 'Remove all',

  'page--pressure-widget-email.success-message': 'Target email configured successfully!',
  'page--pressure-widget-email.form.input-tag.label': 'Targets',
  'page--pressure-widget-email.form.input-tag.validation.invalid-target-format': 'Invalid pattern. Fill in as explained above.',
  'page--pressure-widget-email.form.input-tag.validation.type-email-registered': 'You have already registered a target for email pressure.',
  'page--pressure-widget-email.form.input-tag.validation.type-phone-registered': 'You have already registered a target for phone pressure.',
  'page--pressure-widget-email.form.email-subject.label': 'Email subject',
  'page--pressure-widget-email.form.email-body.label': 'Body of the email that will be sent',
  'page--pressure-widget-email.form.disable-edit-field.label': 'Disable email editing and body editing',
  'page--pressure-widget-email.form.disable-edit-field.value.yes': 'Yes',
  'page--pressure-widget-email.form.disable-edit-field.value.no': 'No',

  'p--pressure-widget--input-tag.info.title': 'How to register targets',
  'p--pressure-widget--input-tag.info.text': 'The target register is very simple and can be done with more than one target at a time. You need to separate the targets in separate lines, and each target must follow the format described below. To register, simply press {keyboardTrigger}. And do not forget to save by clicking the button in the upper right corner of the screen.',
  'p--pressure-widget--input-tag.info.item.target-format': 'Target format: {format} (required to use the {lt} and {gt} characters to group the email)',
  'p--pressure-widget--input-tag.info.item.target-format.example': 'Name <email@hostname.com>',
  'p--pressure-widget--input-tag.info.item.special-chars': 'In both types of pressure it is mandatory to use the characters {lt} and {gt} to group the email or telephone',
  'p--pressure-widget--input-tag.info.item.sorting': 'The targets will be displayed in random order in the pressure widget. That is, each time the mobilization is accessed, the display order will be different.',

  'page--pressure-widget-email.form.validation.required': 'Required field',

  'page--pressure-widget-finish.success-message': 'Post-pressure form saved successfully!',

  'pressure-widget--tell-a-friend.message': 'Pressure sent',

  'pressure-widget--target-list.label.email': `
    Who will you press? ({targetsCount} {targetsCount, plural,
      one {target}
      other {targets}
    })
  `,
  'pressure-widget--target-list.label.pressure': `
    Select who you want to press ({targetsCount} {targetsCount, plural,
      one {target}
      other {targets}
    })
  `,

  'p--activists-management.header.title': 'User base',
  'p--activists-management.header.button.upload.text': 'Upload',
  'p--activists-management.header.button.download.text': 'Download',
  'p--activists-management.content.title': '{totalNumber} people',
  'p--activists-management.content.button.tagging.text': 'To tag',
  'p--activists-management.content.button.email.text': 'Email',
  'p--activists-management.content.form-tagging.tags.placeholder': 'Enter tags separated by commas',
  'p--activists-management.content.form-tagging.button.text': 'Add tag',
  'p--activists-management.content.form-tagging.success.message': 'Tags successfully added to {taggedNumber} people',
  'p--activists-management.content.form-tagging.success.undo': 'Undo',
  'p--activists-management.content.activist-spotlight.title': 'Selected profile',
  'p--activists-management.content.activist-spotlight.email.label': 'Email',
  'p--activists-management.content.activist-spotlight.phone.label': 'Phone',
  'p--activists-management.content.activist-spotlight.mobilizations.label': 'Mobilizations',
  'p--activists-management.content.activist-spotlight.tags.label': 'Hang tags',
  'p--activists-management.content.activist-spotlight.form-tagging.button.text': 'Add',

  'activists-management.c--filterable-list.activist.placeholder': 'Who are you looking for?',

  'activists-management.c--filterable-list.suggest.placeholder': 'Filter by mobilizations or forms',
  'activists-management.c--filterable-list.suggest.operators.label': 'Operators',
  'activists-management.c--filterable-list.suggest.operators.options.or.label': 'or',
  'activists-management.c--filterable-list.suggest.operators.options.and.label': 'and',
  'activists-management.c--filterable-list.suggest.segment.donations.label': 'Donations',
  'activists-management.c--filterable-list.suggest.segment.pressures.label': 'Pressures',
  'activists-management.c--filterable-list.suggest.segment.gen-forms.label': 'Generic Forms',
  'activists-management.c--filterable-list.suggest.segment.other-tags.label': 'Other labels',

  'activists-management.c--filterable-list.period.options.today': 'Today',
  'activists-management.c--filterable-list.period.options.last-week': 'Last week',
  'activists-management.c--filterable-list.period.options.last-fortnight': 'In the last 15 days',
  'activists-management.c--filterable-list.period.options.last-month': 'In the last 30 days',
  'activists-management.c--filterable-list.period.options.last-quarter': 'In the last 3 months',
  'activists-management.c--filterable-list.period.options.last-year': 'On the last year',
  'activists-management.c--filterable-list.period.options.always': 'Ever',
  'activists-management.c--filterable-list.period.options.custom-period': 'Customize period ...',

  // Component FinishPostDonation on Donation
  'widgets.components--donation.finish-post-donation-messages.donation-ok': 'THANK YOU! Your donation has been recorded :) It will be processed automatically once a month, starting in 31 days.',
  'widgets.components--donation.finish-post-donation-messages.not-now': 'It\'s OK! Thank you for your support :)',
  'widgets.components--donation.finish-post-donation.value-list': '$ {value} / month',
  'widgets.components--donation.finish-post-donation.no-action': 'No action available',
  'widgets.components--donation.finish-post-donation.title-component': 'YAY! Your donation has been processed :)',
  'widgets.components--donation.finish-post-donation.improve-impact-question': 'You have just supported the basic structure of the Panela de Pressão, but to reach its maximum potential we\'ll need to hire someone totally dedicated to support people who build campaigns through the platform. With a small monthly donation, you can help us hire this person!',
  'widgets.components--donation.finish-post-donation.improve-impact-solution': 'Make your contribution now',
  'widgets.components--donation.finish-post-donation.improve-impact-explanation': 'Your contribution will be made automatically once a month, starting in 31 days.',
  'widgets.components--donation.finish-post-donation.support-every-month': 'SUPPORT EVERY MONTH',
  'widgets.components--donation.finish-post-donation.not-now': 'NOT NOW',
  'widgets.components--donation.finish-post-donation-messages.tell-a-friend.text': 'Share with your crew to spread the Activist Mutirão around every corner:'
}
