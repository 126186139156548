import React from "react";

function LoadingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#424242"
        d="M15.364 2.64A8.941 8.941 0 009 .006c-1.45 0-2.89.352-4.165 1.02a9.049 9.049 0 00-2.022 1.447V.005H1.405v4.922h4.922V3.52H3.75A7.62 7.62 0 019 1.41c4.187 0 7.594 3.407 7.594 7.595 0 .233-.01.468-.032.698l1.4.128c.025-.273.038-.55.038-.826h-.703H18a8.942 8.942 0 00-2.636-6.364zM3.084 13.766l-1.095.882c.318.395.672.766 1.052 1.101l.931-1.053a7.654 7.654 0 01-.888-.93zM1.836 11.53l-1.326.467c.168.478.38.945.627 1.389l1.228-.685a7.58 7.58 0 01-.53-1.172zM1.406 9.005H0c0 .508.043 1.019.127 1.516l1.387-.235a7.672 7.672 0 01-.108-1.281zM5.004 15.463l-.741 1.195a9 9 0 001.357.69l.529-1.303a7.579 7.579 0 01-1.145-.582zM16.338 10.968c-.11.413-.257.82-.436 1.208l1.277.588a8.98 8.98 0 00.517-1.433l-1.358-.363zM13.484 15.134a7.614 7.614 0 01-1.097.67l.628 1.257a9.03 9.03 0 001.3-.793l-.83-1.134zM15.268 13.293a7.662 7.662 0 01-.814.996l1.01.978a9.07 9.07 0 00.963-1.178l-1.16-.796zM11.193 16.277a7.566 7.566 0 01-1.257.264l.17 1.396a8.98 8.98 0 001.492-.313l-.405-1.347zM7.375 16.424L7.076 17.8a9.038 9.038 0 001.51.196l.064-1.405a7.621 7.621 0 01-1.275-.166z"
      ></path>
    </svg>
  );
}

export default LoadingIcon;
