"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DraftEditor", {
  enumerable: true,
  get: function () {
    return _draftEditor.default;
  }
});
Object.defineProperty(exports, "SlateEditor", {
  enumerable: true,
  get: function () {
    return _slateEditor.default;
  }
});
var _draftEditor = _interopRequireDefault(require("./draft-editor"));
var _slateEditor = _interopRequireDefault(require("./slate-editor"));