"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numberUtils = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _formatNumber = _interopRequireDefault(require("format-number"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
// Options
//
const numberOptions = {
  integerSeparator: '.',
  decimal: ','
};
const currencyOptions = _objectSpread(_objectSpread({}, numberOptions), {}, {
  prefix: 'R$ ',
  padRight: 2,
  truncate: 2
});
const currencyIntOptions = _objectSpread(_objectSpread({}, currencyOptions), {}, {
  padRight: 0,
  truncate: 0
});
const floatOptions = {
  integerSeparator: '',
  decimal: '.',
  padRight: 2
};
const integerOptions = {
  integerSeparator: '',
  truncate: 0
};

//
// Helpers
//
const currency = (0, _formatNumber.default)(currencyOptions);
const currencyInt = (0, _formatNumber.default)(currencyIntOptions);
const number = (0, _formatNumber.default)(numberOptions);
const float = (0, _formatNumber.default)(floatOptions);
const integer = (0, _formatNumber.default)(integerOptions);
const numberUtils = exports.numberUtils = {
  currency,
  currencyInt,
  number,
  float,
  integer
};