import React from 'react'

const NotFound = () => (
  <div className='container'>
    <h1>Ops! 404!</h1>
    <p>Página <em>não</em> encontrada!</p>
  </div>
)

export default NotFound
