//
// Asynchrounous
//
export const ASYNC_DONATION_TRANSACTION_CREATE_REQUEST = 'ASYNC_DONATION_TRANSACTION_CREATE_REQUEST'
export const ASYNC_DONATION_TRANSACTION_CREATE_SUCCESS = 'ASYNC_DONATION_TRANSACTION_CREATE_SUCCESS'
export const ASYNC_DONATION_TRANSACTION_CREATE_FAILURE = 'ASYNC_DONATION_TRANSACTION_CREATE_FAILURE'

//
// Synchrounous
//
export const SET_DONATION_CUSTOMER_DATA = 'SET_DONATION_CUSTOMER_DATA'
