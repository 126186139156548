export const FETCH = 'community/FETCH'

export const FETCH_SUCCESS = 'community/FETCH_SUCCESS'

export const FETCH_FAIL = 'community/FETCH_FAIL'

export const ADD = 'community/ADD'

export const EDIT = 'community/EDIT'

export const SELECT = 'community/SELECT'

export const UNSET = 'community/UNSET'

export const SET_ERRORS = 'community/SET_ERRORS'

export const CLEAR_ERROR = 'community/CLEAR_ERRORS'

export const FETCH_DNS_HOSTED_ZONES_REQUEST = 'FETCH_DNS_HOSTED_ZONES_REQUEST'
export const FETCH_DNS_HOSTED_ZONES_SUCCESS = 'FETCH_DNS_HOSTED_ZONES_SUCCESS'
export const FETCH_DNS_HOSTED_ZONES_FAILURE = 'FETCH_DNS_HOSTED_ZONES_FAILURE'

export const UPDATE_DNS_HOSTED_ZONE = 'UPDATE_DNS_HOSTED_ZONE';

export const ADD_DNS_HOSTED_ZONE_REQUEST = 'ADD_DNS_HOSTED_ZONE_REQUEST'
export const ADD_DNS_HOSTED_ZONE_SUCCESS = 'ADD_DNS_HOSTED_ZONE_SUCCESS'
export const ADD_DNS_HOSTED_ZONE_FAILURE = 'ADD_DNS_HOSTED_ZONE_FAILURE'

export const FETCH_DNS_RECORDS_REQUEST = 'FETCH_DNS_RECORDS_REQUEST'
export const FETCH_DNS_RECORDS_SUCCESS = 'FETCH_DNS_RECORDS_SUCCESS'
export const FETCH_DNS_RECORDS_FAILURE = 'FETCH_DNS_RECORDS_FAILURE'

export const DELETE_DNS_HOSTED_ZONE_REQUEST = 'DELETE_DNS_HOSTED_ZONE_REQUEST'
export const DELETE_DNS_HOSTED_ZONE_SUCCESS = 'DELETE_DNS_HOSTED_ZONE_SUCCESS'
export const DELETE_DNS_HOSTED_ZONE_FAILURE = 'DELETE_DNS_HOSTED_ZONE_FAILURE'

export const ADD_DNS_RECORD_REQUEST = 'ADD_DNS_RECORD_REQUEST'
export const ADD_DNS_RECORD_SUCCESS = 'ADD_DNS_RECORD_SUCCESS'
export const ADD_DNS_RECORD_FAILURE = 'ADD_DNS_RECORD_FAILURE'

export const DELETE_DNS_RECORD_REQUEST = 'DELETE_DNS_RECORD_REQUEST'
export const DELETE_DNS_RECORD_SUCCESS = 'DELETE_DNS_RECORD_SUCCESS'
export const DELETE_DNS_RECORD_FAILURE = 'DELETE_DNS_RECORD_FAILURE'

export const CHECK_DNS_HOSTED_ZONE_REQUEST = 'CHECK_DNS_HOSTED_ZONE_REQUEST'
export const CHECK_DNS_HOSTED_ZONE_SUCCESS = 'CHECK_DNS_HOSTED_ZONE_SUCCESS'
export const CHECK_DNS_HOSTED_ZONE_FAILURE = 'CHECK_DNS_HOSTED_ZONE_FAILURE'

export const ASYNC_INVITE_REQUEST = 'ASYNC_INVITE_REQUEST'
export const ASYNC_INVITE_SUCCESS = 'ASYNC_INVITE_SUCCESS'
export const ASYNC_INVITE_FAILURE = 'ASYNC_INVITE_FAILURE'

export const SET_FORCED_SUBMIT = 'SET_FORCED_SUBMIT'

export const REHYDRATE = 'community/REHYDRATE'
