/* eslint-disable */
import pt_BR from './pt-BR'
import en from './en'
import es from './es'
/* eslint-disable */

export default {
  'pt-BR': pt_BR,
  // Without region
  en,
  es
}
