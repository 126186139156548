export const SELECT_WIDGET = 'SELECT_WIDGET'

export const FETCH_WIDGETS_REQUEST = 'FETCH_WIDGETS_REQUEST'
export const FETCH_WIDGETS_SUCCESS = 'FETCH_WIDGETS_SUCCESS'
export const FETCH_WIDGETS_FAILURE = 'FETCH_WIDGETS_FAILURE'

export const FILTER_WIDGETS_REQUEST = 'FILTER_WIDGETS_REQUEST'
export const FILTER_WIDGETS_SUCCESS = 'FILTER_WIDGETS_SUCCESS'
export const FILTER_WIDGETS_FAILURE = 'FILTER_WIDGETS_FAILURE'

export const UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST'
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS'
export const UPDATE_WIDGET_FAILURE = 'UPDATE_WIDGET_FAILURE'

export const WIDGET_FORM_ENTRY_CREATE_REQUEST = 'WIDGET_FORM_ENTRY_CREATE_REQUEST'
export const WIDGET_FORM_ENTRY_CREATE_SUCCESS = 'WIDGET_FORM_ENTRY_CREATE_SUCCESS'
export const WIDGET_FORM_ENTRY_CREATE_FAILURE = 'WIDGET_FORM_ENTRY_CREATE_FAILURE'

export const ADD_WIDGETS_SUCCESS = 'ADD_WIDGETS_SUCCESS'
export const SET_WIDGET_LIST = 'SET_WIDGET_LIST'

export const FETCH_BLOCKS_REQUEST = 'FETCH_BLOCKS_REQUEST'
export const FETCH_BLOCKS_SUCCESS = 'FETCH_BLOCKS_SUCCESS'
export const FETCH_BLOCKS_FAILURE = 'FETCH_BLOCKS_FAILURE'

export const UPDATE_BLOCK_REQUEST = 'UPDATE_BLOCK_REQUEST'
export const UPDATE_BLOCK_SUCCESS = 'UPDATE_BLOCK_SUCCESS'
export const UPDATE_BLOCK_FAILURE = 'UPDATE_BLOCK_FAILURE'
export const UPDATE_BLOCK_BATCH = 'UPDATE_BLOCK_BATCH'

export const ADD_BLOCK_REQUEST = 'ADD_BLOCK_REQUEST'
export const ADD_BLOCK_SUCCESS = 'ADD_BLOCK_SUCCESS'
export const ADD_BLOCK_FAILURE = 'ADD_BLOCK_FAILURE'

export const DESTROY_BLOCK_REQUEST = 'DESTROY_BLOCK_REQUEST'
export const DESTROY_BLOCK_SUCCESS = 'DESTROY_BLOCK_SUCCESS'
export const DESTROY_BLOCK_FAILURE = 'DESTROY_BLOCK_FAILURE'

export const FILTER_BLOCKS_REQUEST = 'FILTER_BLOCKS_REQUEST'
export const FILTER_BLOCKS_SUCCESS = 'FILTER_BLOCKS_SUCCESS'
export const FILTER_BLOCKS_FAILURE = 'FILTER_BLOCKS_FAILURE'

export const FILTER_BLOCK_REQUEST = 'FILTER_BLOCKS_REQUEST'
export const FILTER_BLOCK_SUCCESS = 'FILTER_BLOCKS_SUCCESS'
export const FILTER_BLOCK_FAILURE = 'FILTER_BLOCKS_FAILURE'

export const CHANGE_BLOCK_BACKGROUND = 'CHANGE_BLOCK_BACKGROUND'

export const MOUSE_OVER = 'MOUSE_OVER'
export const MOUSE_OUT = 'MOUSE_OUT'

export const TURN_ON_EDITION = 'TURN_ON_EDITION'
export const TURN_OFF_EDITION = 'TURN_OFF_EDITION'

export const MOVE_BLOCK_UP = 'MOVE_BLOCK_UP'
export const MOVE_BLOCK_DOWN = 'MOVE_BLOCK_DOWN'

export const LOADING_FILE = 'LOADING_FILE'
export const FINISH_LOADING_FILE = 'FINISH_LOADING_FILE'

export const ADD_MOBILIZATION_REQUEST = 'ADD_MOBILIZATION_REQUEST'
export const ADD_MOBILIZATION_SUCCESS = 'ADD_MOBILIZATION_SUCCESS'
export const ADD_MOBILIZATION_FAILURE = 'ADD_MOBILIZATION_FAILURE'

export const FETCH_MOBILIZATIONS_REQUEST = 'FETCH_MOBILIZATIONS_REQUEST'
export const FETCH_MOBILIZATIONS_SUCCESS = 'FETCH_MOBILIZATIONS_SUCCESS'
export const FETCH_MOBILIZATIONS_FAILURE = 'FETCH_MOBILIZATIONS_FAILURE'

export const SELECT_MOBILIZATION = 'SELECT_MOBILIZATION'

export const UPDATE_MOBILIZATION_REQUEST = 'UPDATE_MOBILIZATION_REQUEST'
export const UPDATE_MOBILIZATION_SUCCESS = 'UPDATE_MOBILIZATION_SUCCESS'
export const UPDATE_MOBILIZATION_FAILURE = 'UPDATE_MOBILIZATION_FAILURE'

export const TOGGLE_MOBILIZATION_MENU = 'TOGGLE_MOBILIZATION_MENU'

export const FILTER_MOBILIZATIONS_REQUEST = 'FILTER_MOBILIZATIONS_REQUEST'
export const FILTER_MOBILIZATIONS_SUCCESS = 'FILTER_MOBILIZATIONS_SUCCESS'
export const FILTER_MOBILIZATIONS_FAILURE = 'FILTER_MOBILIZATIONS_FAILURE'

export const EXPORT_DATACLIP_REQUEST = 'EXPORT_DATACLIP_REQUEST'
export const EXPORT_DATACLIP_SUCCESS = 'EXPORT_DATACLIP_SUCCESS'
export const EXPORT_DATACLIP_FAILURE = 'EXPORT_DATACLIP_FAILURE'
export const EXPORT_DATACLIP_NO_DATA_FOUND = 'EXPORT_DATACLIP_NO_DATA_FOUND'
export const EXPORT_DATACLIP_MOUNT = 'EXPORT_DATACLIP_MOUNT'
