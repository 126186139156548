"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = require("draft-js");
var _default = (contentBlock, callback) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    if (entityKey) {
      const entityInstance = _draftJs.Entity.get(entityKey);
      return entityInstance !== null && entityInstance.getType() === 'LINK';
    }
    return false;
  }, callback);
};
exports.default = _default;