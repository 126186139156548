"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = require("draft-js");
var _immutable = require("immutable");
const getSelectionEntities = (editorState, entityType) => {
  // Selection cursor
  const targetSelection = editorState.getSelection();
  const endOffset = targetSelection.getEndOffset();
  const currentContent = editorState.getCurrentContent();
  const block = currentContent.getBlockForKey(targetSelection.getStartKey());
  let selectionEntitySet = new _immutable.OrderedSet();
  block.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && _draftJs.Entity.get(entityKey).getType() === entityType;
  }, (start, end) => {
    const isSelected = end >= endOffset && start <= endOffset;
    if (isSelected) {
      selectionEntitySet = selectionEntitySet.add({
        blockKey: block.getKey(),
        entityKey: block.getEntityAt(start),
        start,
        end
      });
    }
  });
  return selectionEntitySet;
};
var _default = exports.default = getSelectionEntities;