const t = type => `subscriptions/${type}`

export const SET_MODIFICATION_TYPE = t('SET_MODIFICATION_TYPE')
export const APPEND_ANIMATION_STACK = t('APPEND_ANIMATION_STACK')
export const REMOVE_ANIMATION_STACK = t('REMOVE_ANIMATION_STACK')

export const ASYNC_RECHARGE_REQUEST = t('ASYNC_RECHARGE_REQUEST')
export const ASYNC_RECHARGE_SUCCESS = t('ASYNC_RECHARGE_SUCCESS')
export const ASYNC_RECHARGE_FAILURE = t('ASYNC_RECHARGE_FAILURE')

export const ASYNC_FETCH_SUCCESS = t('ASYNC_FETCH_SUCCESS')
export const ASYNC_FETCH_FAILURE = t('ASYNC_FETCH_FAILURE')
