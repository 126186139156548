"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSelectedBlocks = exports.getEntitySelectionState = exports.default = void 0;
var _draftJs = require("draft-js");
var _getSelectionEntities = _interopRequireDefault(require("./getSelectionEntities"));
const getEntitySelectionState = (editorState, selectionState, entityType) => {
  // Selection cursor
  const currentContent = editorState.getCurrentContent();
  const endOffset = selectionState.getEndOffset();
  const block = currentContent.getBlockForKey(selectionState.getStartKey());
  let entitySelectionState;
  block.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey && _draftJs.Entity.get(entityKey).getType() === entityType;
  }, (start, end) => {
    const isSelected = end >= endOffset && start <= endOffset;
    if (isSelected) {
      entitySelectionState = selectionState.merge({
        anchorOffset: start,
        focusOffset: end
      });
    }
  });
  return entitySelectionState;
};

/*
 * getSelectedBlocks
 */
exports.getEntitySelectionState = getEntitySelectionState;
const getSelectedBlocks = (contentState, selectionState) => {
  const anchorKey = selectionState.getStartKey();
  const focusKey = selectionState.getEndKey();
  const isSameBlock = anchorKey === focusKey;
  const startingBlock = contentState.getBlockForKey(anchorKey);
  if (!startingBlock) {
    return [];
  }
  let selectedBlocks = [startingBlock];
  if (!isSameBlock) {
    let blockKey = anchorKey;
    while (blockKey !== focusKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);
      if (!nextBlock) {
        selectedBlocks = [];
        break;
      }
      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
    }
  }
  return selectedBlocks;
};
exports.getSelectedBlocks = getSelectedBlocks;
const getBlockSelectionState = (contentBlock, selectionState) => {
  const anchorKey = selectionState.getStartKey();
  const focusKey = selectionState.getEndKey();

  // Selection for this block
  const blockKey = contentBlock.getKey();
  const startOffset = blockKey === anchorKey ? selectionState.getStartOffset() : 0;
  const endOffset = blockKey === focusKey ? selectionState.getEndOffset() : contentBlock.getText().length;
  return _draftJs.SelectionState.createEmpty(blockKey).merge({
    anchorOffset: startOffset,
    focusOffset: endOffset
  });
};

/*
 * Utils.js
 *
 * - toggleLink(editorState: EditorState, data: object)
 */
var _default = exports.default = {
  toggleInlineStyle: (editorState, inlineStyle) => {
    if (inlineStyle.indexOf(':') > 0) {
      // remove and add new custom inline style
      let contentWithoutStyle = editorState.getCurrentContent();
      const prefix = inlineStyle.split(':')[0];
      const styles = editorState.getCurrentInlineStyle().filter(style => style.startsWith(prefix));
      if (styles.size > 0) {
        styles.forEach(style => {
          contentWithoutStyle = _draftJs.Modifier.removeInlineStyle(contentWithoutStyle, editorState.getSelection(), style);
        });
        return _draftJs.RichUtils.toggleInlineStyle(
        // apply custom inline style in content without style
        _draftJs.EditorState.push(editorState, contentWithoutStyle, 'change-inline-style'), inlineStyle);
      }
    }
    return _draftJs.RichUtils.toggleInlineStyle(editorState, inlineStyle);
  },
  toggleLink: (editorState, data) => {
    /* This code need refactor */
    // Save editorState then apply link in loop
    let editorStateMutable = editorState;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const arrayBlocks = getSelectedBlocks(contentState, selection);

      // Should create entity when text select
      // and merge entity data when block type selected is atomic
      let entityKey;
      arrayBlocks.forEach(block => {
        const entity = block.getEntityAt(0);
        if (entity) {
          // When has entity selected
          if (block.getType() === 'atomic') {
            if (!data) {
              // Remove
              _draftJs.Entity.mergeData(entity, {
                href: null
              });
            } else {
              // Update
              _draftJs.Entity.mergeData(entity, data);
            }
          } else {
            const blockSelectionState = getBlockSelectionState(block, selection);
            const entitySelectionState = getEntitySelectionState(editorState, blockSelectionState, 'LINK');
            if (entitySelectionState && data) {
              editorStateMutable = _draftJs.RichUtils.toggleLink(editorStateMutable, blockSelectionState, _draftJs.Entity.create('LINK', 'MUTABLE', data));
            } else {
              editorStateMutable = _draftJs.RichUtils.toggleLink(editorStateMutable, blockSelectionState, null);
            }
          }
        } else if (block.getText().length > 0) {
          if (!entityKey) {
            // Ensure only a entity been created
            entityKey = data ? _draftJs.Entity.create('LINK', 'MUTABLE', data) : null;
          }

          // Toggle link
          const blockSelectionState = getBlockSelectionState(block, selection);
          editorStateMutable = _draftJs.RichUtils.toggleLink(
          // Apply entity in content mutable to ensure
          // that link before loop has been applied
          editorStateMutable, blockSelectionState, entityKey);
        }
      });
      return editorStateMutable;
    } else {
      const selectionEntity = (0, _getSelectionEntities.default)(editorState, 'LINK').last();
      if (selectionEntity) {
        _draftJs.Entity.mergeData(selectionEntity.entityKey, data);
      }
      return editorState;
    }
  },
  getEntityInstance: (editorState, selectionState, entityType) => {
    const entitySelectionState = getEntitySelectionState(editorState, selectionState, entityType);
    if (entitySelectionState) {
      const block = editorState.getCurrentContent().getBlockForKey(entitySelectionState.getStartKey());
      const entityKey = block.getEntityAt(entitySelectionState.getStartOffset());
      return entityKey ? _draftJs.Entity.get(entityKey) : undefined;
    }
  }
};